import React from 'react'
import { Link } from 'react-router-dom/dist'

const Footer = () => {
    return (
        <footer class="main-footer footer-style-fifteen pull-up">
            <div class="bg footer-bg-15"></div>

            <div class="footer-bottom">
                <div class="auto-container">
                    <div class="inner-container">
                        <div class="copyright-text">© <a href="#">kana-travels</a> 2023 | Touts Droits Réservés </div>

                        <ul class="footer-nav">
                            <li><a href="#">Terms et Condition</a></li>
                            <li><a href="#">Politique</a></li>
                            <li><a href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer