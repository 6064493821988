import React from 'react'
import { Link } from 'react-router-dom/dist'

const Home = () => {
    return (
        <section>
            <section class="banner-section-fifteen">
                <div class="bg" style={{ backgroundImage: `url(images/banner/bg-9.jpg)` }}></div>
                <div class="auto-container">
                    <div class="row">
                        <div class="content-columnn col-lg-6 order-lg-2">
                            <div class="content-box">
                                <h1 class="title">Etudier en France</h1>

                            </div>
                        </div>
                        <div class="image-column col-lg-6">
                            <div class="image-box wow fadeInRight" data-wow-delay="1500ms">
                                <figure class="image overlay-anim"><img src="images/banner/15.jpg" alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" class="about-section-fifteen">
                <div class="auto-container">
                    <div class="row">

                        <div class="content-column col-lg-6 col-md-12 col-sm-12 order-lg-2">
                            <div class="inner-column wow fadeInLeft">
                                <div class="sec-title">
                                    <span class="sub-title">Apropos</span>
                                    <h2>Transforming your business <br />with technology</h2>
                                    <div class="text">It is a long established fact that a reader will be distracted by the
                                        readable content of a page when looking at its layout</div>
                                </div>

                                <div class="about-block-fifteen">
                                    <div class="inner-box">
                                        <h4 class="title"><i class="fa fa-angles-right"></i>Wealth Guard</h4>
                                        <div class="text">It is a long established fact that a reader will be layout hk
                                            distracted by the readable sometimes on purpose</div>
                                    </div>
                                </div>

                                <div class="about-block-fifteen">
                                    <div class="inner-box">
                                        <h4 class="title"><i class="fa fa-angles-right"></i>Cash wise</h4>
                                        <div class="text">It is a long established fact that a reader will be layout hk
                                            distracted by the readable sometimes on purpose</div>
                                    </div>
                                </div>

                                <div class="btn-box">
                                    <a href="page-about.html" class="theme-btn btn-style-one "><span class="btn-title">Read
                                        More <i class="fa fa-arrow-right"></i></span></a>
                                    <div class="auther-info">
                                        <figure class="thumb"><a href="#"><img src="images/resource/about15-2.jpg"
                                            alt="" /></a></figure>
                                        <span class="designation">Web Designer</span>
                                        <h6 class="name">Leslie Alexander</h6>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="image-column col-lg-6 col-md-12 col-sm-12">
                            <div class="inner-column wow fadeInRight">
                                <div class="image-box">
                                    <figure class="image-1 overlay-anim wow fadeInUp"><img
                                        src="images/resource/about15-1.jpg" alt="" /></figure>
                                    <a href="https://www.youtube.com/watch?v=Fvae8nxzVz4" class="play-btn" data-fancybox=""
                                        data-caption=""><i class="icon fa fa-play" aria-hidden="true"></i></a>
                                    <div class="exp-box">
                                        <div class="inner">
                                            <i class="icon fa fa-medal"></i>
                                            <h2 class="title">2</h2>
                                        </div>
                                        <p class="text">Ans d'expérience</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="clients-section-four pt-0">
                <div class="auto-container">
                    <div class="sponsors-outer">
                        <div class="row">
                            <div class="col-md-4">
                                <li class="client-block"> <a href="#"><img src="images/clients/4-1.png" alt="" /></a> </li>
                            </div>
                            <div class="col-md-4">
                                <li class="client-block"> <a href="#"><img src="images/clients/4-2.png" alt="" /></a> </li>

                            </div>
                            <div class="col-md-4">
                                <li class="client-block"> <a href="#"><img src="images/clients/4-3.png" alt="" /></a> </li>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="why-choose-us-five pt-4">
                <div class="auto-container">
                    <div class="row">

                        <div class="content-column col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="400ms">
                            <div class="inner-column">
                                <div class="sec-title">
                                    <span class="sub-title">who we are</span>
                                    <h2>Digital solutions for your <br />every need</h2>
                                    <div class="text">It is a long established fact that a reader will be distracted by the
                                        readable content of a page when looking at its layout established fact that a reader
                                        will be layout hk distracted by the readable sometimes on purpose Primis porta augue
                                        odio libero suspendisse luctus cras magna malesuada torquent, donec id vivamus
                                        faucibus lacinia est ante imperdiet.</div>
                                </div>
                                <div class="row">

                                    <div class="feature-block-five col-lg-6 col-md-6 col-sm-12">
                                        <div class="inner">
                                            <i class="icon flaticon-puzzle"></i>
                                            <h4 class="title">Professional <br />Consultant</h4>
                                        </div>
                                    </div>

                                    <div class="feature-block-five col-lg-6 col-md-6 col-sm-12">
                                        <div class="inner">
                                            <i class="icon flaticon-creativity"></i>
                                            <h4 class="title">Manage IT <br />Services</h4>
                                        </div>
                                    </div>
                                </div>
                                <a href="page-about.html" class="theme-btn btn-style-one read-more"><span
                                    class="btn-title">Learn More </span></a>
                            </div>
                        </div>

                        <div class="image-column col-lg-6 col-md-12 col-sm-12">
                            <div class="inner-column">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="image-box wow fadeInUp">
                                            <figure class="image overlay-anim"><a href="#"><img
                                                src="images/resource/why-us5-1.jpg" alt="" /></a></figure>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="image-box wow fadeInUp" data-wow-delay="300ms">
                                            <figure class="image-2 overlay-anim"><a href="#"><img
                                                src="images/resource/why-us5-2.jpg" alt="" /></a></figure>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="image-box wow fadeInUp" data-wow-delay="600ms">
                                            <figure class="image-3 overlay-anim"><a href="#"><img
                                                src="images/resource/why-us5-3.jpg" alt="" /></a></figure>
                                        </div>
                                    </div>
                                </div>
                                <div class="exp-box wow fadeIn" data-wow-delay="900ms">
                                    <div class="inner">
                                        <span class="icon flaticon-title"></span>
                                        <h4 class="title">230+</h4>
                                        <span class="text">Project Complete</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="call-to-action-five p-0">
                <div class="auto-container">
                    <div class="outer-box">
                        <div class="image"><img src="images/resource/cta2.jpg" alt="" /></div>
                        <div class="sec-title">
                            <span class="icon fa fa-phone"></span>
                            <h2 class="title">Contact : <a href="#">+33 7 53 54 44 17</a></h2>
                        </div>
                        <a href="tel:+33753544417" class="theme-btn btn-style-one"><span class="btn-title">Appelez</span></a>
                    </div>
                </div>
            </section>

            
            {/* <section id="testimonial" class="testimonial-section-thirteen">
			<div class="auto-container">
				<div class="sec-title">
					<span class="sub-title">Clients talk</span>
					<h2>Building your brand in the <br/>digital world</h2>
					<div class="btn-box">
						<a href="page-testimonial.html" class="theme-btn btn-style-one"><span class="btn-title">View
								More</span></a>
					</div>
				</div>

				<div class="two-items-carousel owl-carousel">

					<div class="testimonial-block-thirteen">
						<div class="inner-box">
							<div class="content-box">
								<span class="icon fa fa-quote-left"></span>
								<figure class="thumb"><img src="images/resource/testi-thumb14-1.png" alt=""/></figure>
								<div class="text">Lorem Ipsum is simply dummy text the printing and kjl typesetting
									industry.
									Many desktop publishing packages web page nhklk editors now use Lorem Ipsum as hkl
									their
									default model text, and ahk search for 'lorem hkljllk ipsum' will uncover</div>
							</div>
							<div class="info-box">
								<div class="rating">
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
								</div>
								<h6 class="name">Jane Cooper</h6>
								<span class="designation">Marketing Coordinator</span>
							</div>
						</div>
					</div>

					<div class="testimonial-block-thirteen">
						<div class="inner-box">
							<div class="content-box">
								<span class="icon fa fa-quote-left"></span>
								<figure class="thumb"><img src="images/resource/testi-thumb14-2.png" alt=""/></figure>
								<div class="text">Lorem Ipsum is simply dummy text the printing and kjl typesetting
									industry.
									Many desktop publishing packages web page nhklk editors now use Lorem Ipsum as hkl
									their
									default model text, and ahk search for 'lorem hkljllk ipsum' will uncover</div>
							</div>
							<div class="info-box">
								<div class="rating">
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
								</div>
								<h6 class="name">Eleanor Pena</h6>
								<span class="designation">President of Sales</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> */}

        

            <section id="projects" class="projects-section-fourteen pt-0">
                <div class="auto-container">
                    <div class="sec-title text-center">
                        <span class="sub-title">Latest portfolio</span>
                        <h2>Building your brand in the <br />digital world</h2>
                    </div>
                    <div class="row">
                        <div class="project-block-fourteen col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
                            data-wow-delay="300ms">
                            <div class="inner-box">
                                <div class="image-box">
                                    <figure class="image"><a href="page-project-details.html"><img
                                        src="images/resource/project15-1.jpg" alt="" /></a></figure>
                                    <div class="info-box">
                                        <h4 class="title"><a href="page-project-details.html">Byte Busters</a></h4>
                                        <a href="page-project-details.html" class="read-more"><i
                                            class="icon fa fa-angles-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="project-block-fourteen col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div class="inner-box">
                                <div class="image-box">
                                    <figure class="image"><a href="page-project-details.html"><img
                                        src="images/resource/project15-2.jpg" alt="" /></a></figure>
                                    <div class="info-box">
                                        <h4 class="title"><a href="page-project-details.html">Digital Dazzle</a></h4>
                                        <a href="page-project-details.html" class="read-more"><i
                                            class="icon fa fa-angles-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="project-block-fourteen col-lg-4 col-md-6 col-sm-12 wow fadeInRight"
                            data-wow-delay="300ms">
                            <div class="inner-box">
                                <div class="image-box">
                                    <figure class="image"><a href="page-project-details.html"><img
                                        src="images/resource/project15-3.jpg" alt="" /></a></figure>
                                    <div class="info-box">
                                        <h4 class="title"><a href="page-project-details.html">Net Ninjas</a></h4>
                                        <a href="page-project-details.html" class="read-more"><i
                                            class="icon fa fa-angles-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="testimonial" class="testimonial-section-thirteen">
                <div class="container pb-70">
                    <div class="sec-title text-center">
                        <h2>Nos Packs</h2>
                    </div>
                    <div class="row">

                        <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="400ms">
                            <div class="inner-box">
                                <div class="title-box">
                                    <h3 class="title">PACK START</h3>
                                </div>
                                <div class="price-box">
                                    <h2 class="price">150.000 FCFA</h2>
                                </div>
                                <ul class="features">
                                    <li><i class="fa fa-circle-check"></i>Accès à la formation :  Deux semaines pour décrocher mon admission</li>
                                    <li><i class="fa fa-circle-check"></i>4 Sessions de travail</li>
                                    <li><i class="fa fa-circle-check"></i>Accès à la communauté pour un suivi personnalisé</li>
                                    <li><i class="fa fa-circle-check"></i>Création et remplissage de votre dossier en ligne</li>
                                    <li><i class="fa fa-circle-check"></i>Les clés pour le choix des écoles et des formations</li>
                                    <li><i class="fa fa-circle-check"></i>Coaching pour l'entretien</li>
                                    <li><i class="fa fa-circle-check"></i>BONUS 1 : Comment éviter les motifis de refus des écoles</li>
                                    <li><i class="fa fa-circle-check"></i>BONUS 2 : Feuille de route de la procédure étude en France</li>
                                    <li><i class="fa fa-circle-check"></i>BONUS 3 : Feuille de calcul des dépenses estimatifs de la procédure</li>
                                    <br /> <br /> <br /> <br />
                                </ul>
                                <a href="#" class="theme-btn btn-style-one read-more"><span class="btn-title">Souscrire <i class="fa fa-arrow-right"></i></span></a>
                            </div>
                        </div>

                        <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div class="inner-box">
                                <div class="title-box">
                                    <h3 class="title">PACK SUCCES</h3>
                                </div>
                                <div class="price-box">
                                    <h2 class="price">200.000 FCFA</h2>
                                </div>
                                <ul class="features">
                                    <li><i class="fa fa-circle-check"></i>Accès à la formation :  Deux semaines pour décrocher mon admission</li>
                                    <li><i class="fa fa-circle-check"></i>Création et remplissage du formulaire de demande de VISA</li>
                                    <li><i class="fa fa-circle-check"></i>4 Sessions de travail</li>
                                    <li><i class="fa fa-circle-check"></i>Accès à une communauté pour un suivi personnalisé</li>
                                    <li><i class="fa fa-circle-check"></i>Conseil dans la recherche de logement</li>
                                    <li><i class="fa fa-circle-check"></i>Coaching pour l'entretien</li>
                                    <li><i class="fa fa-circle-check"></i>Conseil à la réservation du billet d'avion</li>
                                    <li><i class="fa fa-circle-check"></i>Conseil à l'achat de l'assurance voyage</li>
                                    <li><i class="fa fa-circle-check"></i>Conseil à la préparation de voyage</li>
                                    <li><i class="fa fa-circle-check"></i>BONUS 1 : Comment éviter les motifis de refus de VISA</li>
                                    <br /> <br /> <br /> <br /> <br />

                                </ul>
                                <a href="#" class="theme-btn btn-style-one read-more"><span class="btn-title">Souscrire <i class="fa fa-arrow-right"></i></span></a>
                            </div>
                        </div>

                        <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="400ms">
                            <div class="inner-box">
                                <div class="title-box">
                                    <h3 class="title">PACK SUCCES+</h3>
                                </div>
                                <div class="price-box">
                                    <h2 class="price">250.000 FCFA</h2>
                                </div>
                                <ul class="features">
                                    <li><i class="fa fa-circle-check"></i>Accès à la formation : Deux semaines pour avoir son dossier béton pour une demande de VISA</li>
                                    <li><i class="fa fa-circle-check"></i>Création et remplissage du formulaire de demande de VISA</li>
                                    <li><i class="fa fa-circle-check"></i>4 Sessions de travail</li>
                                    <li><i class="fa fa-circle-check"></i>Accès à la communauté pour un suivi personnalisé</li>
                                    <li><i class="fa fa-circle-check"></i>Conseils dans la recherche de logement</li>
                                    <li><i class="fa fa-circle-check"></i>Conseils à la préparation de la caution bancaire</li>
                                    <li><i class="fa fa-circle-check"></i>Conseils à la préparation du billet d'avion</li>
                                    <li><i class="fa fa-circle-check"></i>Conseils à l'achat de l'assurance voyage</li>
                                    <li><i class="fa fa-circle-check"></i>Conseils à la préparation de voyage</li>
                                    <li><i class="fa fa-circle-check"></i>BONUS 1 : 8 scéances individuelle avec moi</li>
                                    <li><i class="fa fa-circle-check"></i>BONUS 2 : PDF livret de la procédure de demande de VISA de A à Z</li>
                                    <br />
                                </ul>
                                <a href="#" class="theme-btn btn-style-one read-more"><span class="btn-title">Souscrire <i class="fa fa-arrow-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </section>
    )
}

export default Home