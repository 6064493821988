import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import React from 'react'

import Home from "./pages/Home";
import Contact from "./pages/Contact";

import Navbar from "./components/components/Navbar";
import Footer from "./components/components/Footer";

const App = () => {
  return (
    <section>

      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer/>
      </Router>

    </section>
  )
}

export default App


