import React from 'react'
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <header id="home" class="main-header header-style-fifteen">
            <div class="header-top">
                <div class="inner-container">
                    <div class="top-left">
                        <ul class="list-style-one style-two">
                            <li><i class="fa fa-envelope"></i> <a
                                href="mailto:kanajespin1@gmail.com">kanajespin1@gmail.com</a></li>
                            <li><i class="fa fa-clock"></i> Lun-Sam: 08h00 - 22h00</li>
                        </ul>
                    </div>

                    <div class="top-right">
                        {/* <ul class="useful-links">
                            <li><a href="#"><i class="far fa-user"></i> Sign In</a></li>
                            <li><a href="#">Register</a></li>
                        </ul> */}
                        <ul class="social-icon-one light">
                            <li><a href="#"><span class="fab fa-facebook-f"></span></a></li>
                            <li><a href="#"><span class="fab fa-instagram"></span></a></li>
                            <li><a href="#"><span class="fab fa-twitter"></span></a></li>
                            <li><a href="#"><span class="fab fa-linkedin"></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="header-lower">
                <div class="main-box">
                    <div class="logo-box">
                        <div class="logo"><a href="#"><img src="images/" alt="" title="KanaTravels"  /></a>
                        </div>
                    </div>

                    <div class="nav-outer">
                        <nav class="nav main-menu">
                            <ul class="navigation onepage-nav">
                                <li class="current"><a href="#home">Accueil</a></li>
                                <li><a href="#about">Apropos</a></li>
                                <li><a href="#services">Services</a></li>
                                <li><a href="#projects">Staff</a></li>
                                <li><a href="#testimonial">Tarifs</a></li>
                            </ul>
                        </nav>
                    </div>

                    <div class="outer-box">
                        {/* <button class="ui-btn search-btn"><i class="icon fa fa-search"></i> Search </button> */}

                        <a href="#" class="theme-btn call-btn"><i class="fa fa-phone"></i> +33 7 53 54 44 17</a>

                        <div class="mobile-nav-toggler"><span class="icon lnr-icon-bars"></span></div>
                    </div>
                </div>
            </div>

            <div class="mobile-menu">
                <div class="menu-backdrop"></div>
                <nav class="menu-box">
                    <div class="upper-box">
                        <div class="nav-logo"><a href="#"><img src="images/" alt="" title="KANA-TRAVELS" /></a>
                        </div>
                        <div class="close-btn"><i class="icon fa fa-times"></i></div>
                    </div>
                    <ul class="navigation onepage-nav clearfix"></ul>
                    <ul class="contact-list-one">
                        <li>
                            <i class="icon lnr-icon-phone-handset"></i>
                            <span class="title">Appelez</span>
                            <div class="text"><a href="tel:+33753544417">+33 7 53 54 44 17</a></div>
                        </li>
                        <li>
                            <i class="icon lnr-icon-envelope1"></i>
                            <span class="title">Send Email</span>
                            <div class="text"><a href="mailto:hkanajespin1@gmail.com">kanajespin1@gmail.com</a></div>
                        </li>
                        <li>
                            <i class="icon lnr-icon-map-marker"></i>
                            <span class="title">Addresse</span>
                            <div class="text">66 Rue, Bordeaux, France</div>
                        </li>
                    </ul>
                    <ul class="social-links">
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </nav>
            </div>

            <div class="sticky-header">
                <div class="auto-container">
                    <div class="inner-container">
                        <div class="logo">
                            <Link to="/" title=""><img src="images/" alt="" title="KANA-TRAVELS" /></Link>
                        </div>

                        <div class="nav-outer">
                            <nav class="main-menu">
                                <div class="navbar-collapse show collapse clearfix">
                                    <ul class="navigation onepage-nav clearfix">
                                    </ul>
                                </div>
                            </nav>

                            <div class="mobile-nav-toggler"><span class="icon lnr-icon-bars"></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbar