import React from 'react'
import { Link } from 'react-router-dom/dist'

const Contact = () => {
  return (
    <div className="container-fluid py-5 mb-5">
            <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: "600px" }}>
                    <h1 className="mb-3">Contact</h1>
                </div>
                <div className="contact-detail position-relative p-5">
                    <div className="row g-5 mb-5 justify-content-center">
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: "64px", height: "64px" }}>
                                    <i className="fas fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Addresse</h4>
                                    <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2637.059410215522!2d2.425917576451165!3d48.62784517130179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5de1a2e6330a3%3A0xffd86cd61978fa49!2s14%20Pl.%20des%20Terrasses%20de%20l&#39;Agora%2C%2091000%20%C3%89vry-Courcouronnes!5e0!3m2!1sfr!2sfr!4v1697197855896!5m2!1sfr!2sfr" target="_blank" className="h5">14 place des terrasses de l'agora, 91000 Evry, France. ( 3e étage tout au fond du couloir a gauche )</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: "64px", height: "64px" }}>
                                    <i className="fa fa-phone text-white"></i>
                                </div>
                                <div className="ms-3 p-4">
                                    <h4 className="text-primary">Contact</h4>
                                    <a className="h5" href="tel:+33 (0)7 69 262 888" target="_blank">+33 (0)7 69 262 888</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: "64px", height: "64px" }}>
                                    <i className="fa fa-envelope text-white"></i>
                                </div>
                                <div className="ms-3 p-3">
                                    <h4 className="text-primary">Email</h4>
                                    <Link className="h5" to="mailto:hminfo.evry@gmail.com" target="_blank">hminfo.evry@gmail.com</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="p-5 h-100 rounded contact-map">
                                <iframe className="rounded w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2637.059410215522!2d2.425917576451165!3d48.62784517130179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5de1a2e6330a3%3A0xffd86cd61978fa49!2s14%20Pl.%20des%20Terrasses%20de%20l&#39;Agora%2C%2091000%20%C3%89vry-Courcouronnes!5e0!3m2!1sfr!2sfr!4v1697197855896!5m2!1sfr!2sfr" style={{border:0, allowfullscreen:"", loading:"lazy", referrerpolicy:"no-referrer-when-downgrade" }}></iframe>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                            <div className="p-5 rounded contact-form">
                                <div className="mb-4">
                                    <input type="text" className="form-control border-0 py-3" placeholder="Votre nom"/>
                                </div>
                                <div className="mb-4">
                                    <input type="email" className="form-control border-0 py-3" placeholder="Votre adresse Email"/>
                                </div>
                                <div className="mb-4">
                                    <input type="number" className="form-control border-0 py-3" placeholder="Votre numero de telephone"/>
                                </div>
                                <div className="mb-4">
                                    <textarea className="w-100 form-control border-0 py-3" rows="6" cols="10" placeholder="Message"></textarea>
                                </div>
                                <div className="text-start">
                                    <button className="btn bg-primary text-white py-3 px-5" type="button">Envoyez Message</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
  )
}

export default Contact